import { render, staticRenderFns } from "./cultural-service-list-page.vue?vue&type=template&id=5924e60b&scoped=true&"
import script from "./cultural-service-list-page.vue?vue&type=script&lang=js&"
export * from "./cultural-service-list-page.vue?vue&type=script&lang=js&"
import style0 from "./cultural-service-list-page.vue?vue&type=style&index=0&id=5924e60b&prod&lang=scss&scoped=true&"
import style1 from "./cultural-service-list-page.vue?vue&type=style&index=1&id=5924e60b&prod&lang=scss&scoped=true&"
import style2 from "./cultural-service-list-page.vue?vue&type=style&index=2&id=5924e60b&prod&lang=scss&scoped=true&"
import style3 from "./cultural-service-list-page.vue?vue&type=style&index=3&id=5924e60b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5924e60b",
  null
  
)

export default component.exports
import {QSkeleton,QImg} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSkeleton,QImg})
