
import i18n from '@/vueI18n'
import { mapGetters, mapActions } from 'vuex'
// import FsLightbox from "fslightbox-vue";

export default {
    name: 'app-cultural-service-view-page',
    computed: {
        ...mapGetters({
            isMobile: "layout/isMobile",
            rows: 'culturalService/view/rows',
            loading: "culturalService/view/loading"
        }),
        isRTL() {
            return i18n.locale == 'ar'
        }
    },
    data() {
        return {
            myhtml: '<div class="the-html" style="display: flex;flex-direction: column;align-items: center !important;justify-content: center !important;width: 100%;text-align: center !important; "><h4 style="margin-bottom:30px">النشاط الثقافى</h4> <p style="font-size: 18px !important">● الأطباء ينصحون كبار السن بقراءة الكتب لتقوية الزاكرة و تساعد أي شخص يشعر بالوحدة فى التفكير بشأن الأنضمام إلى مجموعة من القراء يتناقشون حول الكتب التي يقرؤونها</p> <p style="font-size: 18px ">و يتوفر هذا النشاط داخل النادى فى الكلوب هاوس بالدور الأول</p> <p style="font-size: 18px "> من الساعة 9 صباحا إلى الساعة 9 مساء</p> <p style="font-size: 18px ">و من أجل أبنائنا الطلبة قام النادى بتوفير المكان المناسب للإستذكار و تلك هى مكتبة توشكى</p> <p style="font-size: 18px ">من 16 سنة   المواعيد من ال 9 صباحا إلى  9  مساء</p></div>',
            slideIndex: 0,
            clinics: [
                { id: 1, avatar: '/images/avatar.png', clinicName: 'Smouha Scan Center', doctor: 'Dr.Ahmed Mohammed', phoneNumber: '+01222862777', time: 'Everyday 5-9 PM', specialization: 'Dermatology and skin care' },
                { id: 2, avatar: '/images/avatar.png', clinicName: 'Smouha Scan Center', doctor: 'Dr.Ahmed Mohammed', phoneNumber: '+01222862777', time: 'Everyday 5-9 PM', specialization: 'Dermatology and skin care' },
                { id: 3, avatar: '/images/avatar.png', clinicName: 'Smouha Scan Center', doctor: 'Dr.Ahmed Mohammed', phoneNumber: '+01222862777', time: 'Everyday 5-9 PM', specialization: 'Dermatology and skin care' },
                // { id: 4, avatar: '/images/avatar.png', clinicName: 'Smouha Scan Center', doctor: 'Dr.Ahmed Mohammed', phoneNumber: '+01222862777', time: 'Everyday 5-9 PM', specialization: 'Dermatology and skin care' },
                // { id: 5, avatar: '/images/avatar.png', clinicName: 'Smouha Scan Center', doctor: 'Dr.Ahmed Mohammed', phoneNumber: '+01222862777', time: 'Everyday 5-9 PM', specialization: 'Dermatology and skin care' },
                // { id: 6, avatar: '/images/avatar.png', clinicName: 'Smouha Scan Center', doctor: 'Dr.Ahmed Mohammed', phoneNumber: '+01222862777', time: 'Everyday 5-9 PM', specialization: 'Dermatology and skin care' }
            ],
        }
    },
    components: {
        // FsLightbox,

    },
    methods: {
        ...mapActions({
            doFetch: 'culturalService/view/doFetch'
        }),
        i18n(key, args) {
            return this.$t(key, args);
        }
    },


    created() {
        // await this.doFind({ id: this.$route.params.id })
        // console.log('record', this.record);
        this.doFetch()
        console.log(this.rows)
    },
}
